import Dashboard from "./Pages/Dashboard";

function App() {

  return (
    <Dashboard />
  );

}

export default App;
