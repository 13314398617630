const baseUri = "http://192.168.1.189:5143"// http://192.168.1.189:5143 https://api.gcdb365.com

function HttpRequest(path, options, deauthorize) {
    return new Promise(async (resolve, reject) => {

        if (window.localStorage.getItem("accessToken")) {
            options.headers = {
                ...options.headers,
                "Authorization": `Bearer ${window.localStorage.getItem("accessToken")}`
            }
        }

        const response = await Fetch(baseUri + path, options).catch(e => {
            console.log(`Cannot fetch ${baseUri + path}`)
            return false
        })

        if (response?.statusCode === 401) {
            if (window.localStorage.getItem("refreshToken")) {

                const refreshOptions = {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${window.localStorage.getItem("refreshToken")}`
                    }
                }

                const refreshResponse = await Fetch(`${baseUri}/auth/refresh`, refreshOptions).catch(e => {
                    console.log(`Cannot refresh token ${baseUri + path}`)
                    return false
                })


                if (!refreshResponse || refreshResponse.statusCode === 401) {
                    window.localStorage.removeItem("accessToken")
                    window.localStorage.removeItem("refreshToken")

                    return deauthorize()
                } else if (refreshResponse.statusText === "OK") {
                    window.localStorage.setItem("accessToken", `${refreshResponse.data.accessToken}`)
                    window.localStorage.setItem("refreshToken", `${refreshResponse.data.refreshToken}`)

                    return resolve(HttpRequest(path, options))
                } else {
                    console.log("UNKNOWN REFRESH RESPONSE")
                    console.log(refreshResponse)
                }

            } else {
                window.localStorage.removeItem("accessToken")
                window.localStorage.removeItem("refreshToken")
                return deauthorize()
            }
        }

        resolve(response)

    })
}

async function Fetch(path, options) {
    return new Promise((resolve, reject) => {

        fetch(path, { ...options, cache: "default" })
            .then(async response => {
                // console.log(response)
                let headers = [...response.headers]

                let resolveObject = {
                    statusCode: response.status,
                    statusText: response.statusText
                }

                let contentType = headers.find(val => val[0] === "content-type")?.[1]

                if (response.status.toString().startsWith("2") || response.status === 400) {
                    if (contentType && contentType.includes("image/")) {
                        resolveObject["data"] = await response.blob()
                    } else if (contentType) {
                        resolveObject["data"] = await response.json()
                    }
                    // resolveObject["data"] = contentType?.includes("image/") ? await response.blob() : await response.json()
                }

                resolve(resolveObject)

            })
            .catch(e => {
                console.log(e)
                reject(e)
            })

    })
}


export default HttpRequest