import { Typography } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import { FetchImageSecure } from "../Miscellaneous/FetchImageSecure"
import { useState, useEffect } from "react"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import HttpRequest from "../Miscellaneous/HttpRequest";


function SearchResultBlock(props) {

    const [page, setPage] = useState(1);

    let [forms, setForms] = useState([])

    async function changePage(event, value) {
        setPage(value);

        if (page !== value) {
            const form = new FormData(document.querySelector(`#searchForm`))

            const searchQuery = new URLSearchParams(Object.fromEntries(form))

            props.setIsLoading(true)

            const searchResponse = await HttpRequest(
                `/form/search?skip=${(value - 1) * 10}&${searchQuery}`,
                {},
                () => props.setIsSigned(null))
                .catch(e => false)


            if (searchResponse?.statusCode.toString()[0] === "2") {
                props.setSearchResult(searchResponse.data)
                props.setNotification(`Found ${searchResponse.data.totalForms} forms`)
            } else {
                props.setNotification(searchResponse?.statusText || "Unknown error")
            }

            props.setIsLoading(null)
        }
    };


    function openForm(formGuId) {

        props.setProcess({ "name": "search", "formGuId": formGuId })

    }

    useEffect(() => {

        async function parseImages() {
            for (let form of props.searchResult.forms) {
                for (let applicant of form.applicants) {
                    if (applicant.photoLink || applicant.originalPhotoLink) {
                        applicant.image = URL.createObjectURL(
                            await FetchImageSecure(applicant.photoLink || applicant.originalPhotoLink).catch(e => null)
                        )
                    } else {
                        applicant.image = null
                    }

                }
            }

            setForms(props.searchResult.forms)
        }
        parseImages()

    }, [props.searchResult])


    return (
        <div style={{ position: "relative" }}>
            {forms.map((form) => (
                <Grid2 key={form.guId} container marginTop={5} padding={0} style={{ cursor: "pointer" }}
                    spacing={1} bgcolor={"whitesmoke"} boxShadow={"0px 2px 7px 3px rgba(0,0,0, 0.07)"}
                    onClick={() => openForm(form.guId)}
                >

                    {form.applicants.map(applicant => (
                        <Grid2 key={applicant.id} md={12} display={"flex"} justifyContent={"space-between"}>

                            <Grid2 md={1} style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "96px",
                                height: "96px",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <img src={applicant.image} alt="preview" style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                    maxHeight: "100%",
                                    maxWidth: "100%"
                                }} />
                            </Grid2>

                            <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                <Typography fontSize={"small"} fontWeight={"bold"}>FirstName</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.firstName}</Typography>

                                <Typography fontSize={"small"} fontWeight={"bold"}>LastName</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.lastName}</Typography>
                            </Grid2>

                            <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                <Typography fontSize={"small"} fontWeight={"bold"}>Birth date</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>
                                    {new Date(applicant.birthDateTime).toLocaleDateString()}
                                </Typography>

                                <Typography fontSize={"small"} fontWeight={"bold"}>Marital status</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.maritalStatus}</Typography>
                            </Grid2>

                            <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                <Typography fontSize={"small"} fontWeight={"bold"}>Email</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.email}</Typography>

                                <Typography fontSize={"small"} fontWeight={"bold"}>Phone</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.phone1}</Typography>
                            </Grid2>

                            <Grid2 md={1} style={{ display: "flex", flexDirection: "column" }}>
                                <Typography fontSize={"small"} fontWeight={"bold"}>Status</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0} fontWeight={"bold"}
                                    color={form.status !== "GOOD" ? "OrangeRed" : "LimeGreen "}>{form.status}</Typography>

                                <Typography fontSize={"small"} fontWeight={"bold"}>Operator</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{form.operator}</Typography>
                            </Grid2>

                            <Grid2 md={1} style={{ display: "flex", flexDirection: "column" }}>
                                <Typography fontSize={"small"} fontWeight={"bold"}>Payment</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}
                                    fontWeight={form.payments[0].paymentType !== "FREE" ? "bold" : ""}
                                    color={form.payments[0].paymentType !== "FREE" ? "#12a0be" : "black"}>
                                    {form.payments[0].paymentType}
                                </Typography>

                                <Typography fontSize={"small"} fontWeight={"bold"}>Amount</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>
                                    {form.payments[0].paymentAmount} / {form.payments[0].paymentStatus}
                                </Typography>
                            </Grid2>

                            <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                <Typography fontSize={"small"} fontWeight={"bold"}>Step</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0} color={"DeepSkyBlue"} fontWeight={"bold"}>
                                    {form.step}
                                </Typography>

                                <Typography fontSize={"small"} fontWeight={"bold"}>Blocked</Typography>
                                <Typography fontSize={"small"} spacing={0} margin={0}>{form.isBlocked ? "Yes" : "No"}</Typography>
                            </Grid2>
                        </Grid2>
                    ))}

                </Grid2>
            ))}

            <Stack spacing={2} alignItems={"end"} marginTop={"30px"} marginBottom={"30px"}>
                <Pagination count={Math.ceil(props.searchResult.totalForms / 10)}
                    page={page} variant="outlined" shape="rounded" onChange={changePage} />
            </Stack>
        </div>
    )

}

export default SearchResultBlock