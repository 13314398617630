import { FormControl, InputLabel, MenuItem, Select, TextField, Button } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import React from "react"
import { FormSteps } from "../Enum/formSteps.js"
import Countries from "../Miscellaneous/Countries.js"
import HttpRequest from "../Miscellaneous/HttpRequest.js"
import { useAuth } from "../Pages/Dashboard.js"


function SearchBar(props) {

    const { setIsSigned } = useAuth();

    async function submit_form(formId) {
        const form = new FormData(document.querySelector(`#${formId}`))

        const searchQuery = new URLSearchParams(Object.fromEntries(form))

        props.setIsLoading(true)
        props.setSearchResult(null)
        const searchResponse = await HttpRequest(`/form/search?skip=0&${searchQuery}`, {}, () => setIsSigned(null)).catch(e => false)


        if (searchResponse?.statusCode.toString()[0] === "2") {
            props.setSearchResult(searchResponse.data)
            props.setNotification(`Found ${searchResponse.data.totalForms} forms`)
        } else {
            props.setNotification(searchResponse?.statusText || "Unknown error")
        }

        props.setIsLoading(null)
    }

    return (
        <form id="searchForm">
            <Grid2 container bgcolor={"whitesmoke"} spacing={2} marginTop={3} boxShadow={"0px 3px 10px 3px rgba(0,0,0, 0.1)"} alignItems={"end"}>
                <Grid2 md={2}>
                    <TextField label={"Guid"} name="formModelGuId" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"First Name"} name="firstName" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"Last Name"} name="lastName" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"Email"} name="email" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"Agent ID"} name="agentId" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel error={null}>By birth country</InputLabel>
                        <Select
                            defaultValue={""}
                            label="By birth country"
                            name={`birthCountry`}
                            //onChange={(event) => maritalStatusChange(event.target.value)}
                            error={null}
                        >
                            <MenuItem value={""}>By birth country</MenuItem>
                            {Countries.map((val, index) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>


                <Grid2 md={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel error={null}>By bad data</InputLabel>
                        <Select
                            defaultValue={""}
                            label="By bad data"
                            name={`badData`}
                            //onChange={(event) => maritalStatusChange(event.target.value)}
                            error={null}
                        >
                            <MenuItem value={""}>By bad data</MenuItem>
                            <MenuItem value={"incorrectPhoto"}>Bad photo</MenuItem>
                            <MenuItem value={"incorrectMaritalStatus"}>Bad marital status</MenuItem>
                            <MenuItem value={"incorrectChildren"}>Bad children amount</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 md={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel error={null}>By Step</InputLabel>
                        <Select
                            defaultValue={""}
                            label="By Step"
                            name={`step`}
                            //onChange={(event) => maritalStatusChange(event.target.value)}
                            error={null}
                        >
                            <MenuItem value={""}>By Step</MenuItem>
                            {Object.values(FormSteps).map((val, index) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 md={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel error={null}>By Payment</InputLabel>
                        <Select
                            defaultValue={""}
                            label="By Payment"
                            name={`PaymentStatus`}
                            //onChange={(event) => maritalStatusChange(event.target.value)}
                            error={null}
                        >
                            <MenuItem value={""}>By Payment</MenuItem>
                            <MenuItem value={"UNPAID"}>Free</MenuItem>
                            <MenuItem value={"PAID"}>Paid</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 md={2}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel error={null}>By Status</InputLabel>
                        <Select
                            defaultValue={""}
                            label="By Status"
                            name={`status`}
                            //onChange={(event) => maritalStatusChange(event.target.value)}
                            error={null}
                        >
                            <MenuItem value={""}>By Status</MenuItem>
                            <MenuItem value={"BAD"}>Bad</MenuItem>
                            <MenuItem value={"GOOD"}>Good</MenuItem>
                            <MenuItem value={"REFUSED"}>Refused</MenuItem>
                            <MenuItem value={"DUPLICATE"}>Duplicate</MenuItem>
                            <MenuItem value={"WINNER"}>Winner</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"Registered from"} type="Date" size="small" focused={true}
                        name="registeredFrom" fullWidth />
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"Registered to"} type="Date" size="small" focused={true}
                        name="registeredTo" fullWidth />
                </Grid2>


                <Grid2 md={2}>
                    <TextField label={"utm_source"} name="utm_source" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"utm_medium"} name="utm_medium" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"utm_campaign"} name="utm_campaign" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"utm_content"} name="utm_content" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <TextField label={"utm_term"} name="utm_term" size="small"></TextField>
                </Grid2>
                <Grid2 md={2}>
                    <Button variant="contained" fullWidth onClick={() => submit_form("searchForm")}>
                        Search
                    </Button>
                </Grid2>
            </Grid2>
        </form>
    )

}

export default SearchBar