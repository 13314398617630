import { useState, useEffect } from "react"
import ModalScreen from "./ModalScreen"
import CloseIcon from '@mui/icons-material/Close';
import HttpRequest from "../Miscellaneous/HttpRequest";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Pagination, Stack, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';


function Agents(props) {

    let [agents, setAgents] = useState(null)

    let [page, setPage] = useState(1)


    const parentBlockStyle = {
        position: "absolute",
        top: "50px",
        left: 0,
        width: "100%",
        backgroundColor: "rgb(240 240 240)",
        boxSizing: "border-box",
        zIndex: 2,
        minHeight: "800px",
        overflowY: "auto"
    }

    function changePage(event, value) {
        setPage(value)
    }

    async function get_Agents() {

        props.setIsLoading(true)
        let response = await HttpRequest(`/agents?offset=${(page - 1) * 10}`, {}, () => props.setIsSigned(null)).catch(e => false)
        console.log(response)
        if (response.statusText === "OK") {
            setAgents(response.data)
        } else {
            props.setNotification(response.data?.msg || response.statusText || "Unknow error")
        }
        props.setIsLoading(false)

    }

    async function deleteWorker(agentId) {
        let confirmation = window.confirm("Are you sure?")

        if (confirmation) {

            props.setIsLoading(true)
            let response = await HttpRequest(`/agents?id=${agentId}`, { method: "DELETE" }, () => props.setIsSigned(null)).catch(e => false)
            console.log(response)
            if (response.statusText === "OK") {
                document.querySelector(`#agent_${agentId}`).style.display = "none"
            } else {
                props.setNotification(response.data?.msg || response.statusText || "Unknow error")
            }
            props.setIsLoading(false)

        }
    }

    function closeForm() {
        props.setProcess(null)
    }


    useEffect(() => {
        get_Agents()
    }, [page])


    return (
        <>
            <ModalScreen zindex={1}></ModalScreen>

            <div style={parentBlockStyle}>

                <CloseIcon fontSize={"large"}
                    style={{ position: "absolute", right: "0px", padding: "10px", cursor: "pointer" }}
                    onClick={closeForm} />

                {agents &&
                    (
                        <>
                            <Grid2 container marginTop={"50px"}>
                                {agents.agents.map(agent =>
                                (
                                    <Grid2 id={`agent_${agent.id}`} md={12} key={agent.id}
                                        display={"flex"} backgroundColor={"white"} padding={"5px"} marginY={"10px"}>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>FirstName</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.first_name}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>LastName</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.last_name}</Typography>
                                        </Grid2>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Email</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.email}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Phone</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.phone}</Typography>
                                        </Grid2>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Operator</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0} fontWeight={"bold"} color={"skyblue"}>
                                                {agent.operator}
                                            </Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Language</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.language}</Typography>
                                        </Grid2>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Balance</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0} fontWeight={"bold"}>{agent.balance}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Next bonus at</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.next_bonus_at}</Typography>
                                        </Grid2>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Free forms</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0} fontWeight={"bold"}>{agent.freeForms || 0}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Paid forms</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{agent.paidForms || 0}</Typography>
                                        </Grid2>

                                        <Grid2 md={2}>
                                            <DeleteIcon md={"small"} style={{ cursor: "pointer" }} onClick={() => deleteWorker(agent.id)} />
                                        </Grid2>

                                    </Grid2>
                                ))
                                }
                            </Grid2>

                            <Stack spacing={2} alignItems={"end"} marginTop={"30px"} marginBottom={"30px"}>
                                <Pagination count={Math.floor(agents.totalAgents % 10 === 0 ? agents.totalAgents / 10 : agents.totalAgents / 10 + 1)}
                                    page={page} variant="outlined" shape="rounded" onChange={changePage} />
                            </Stack>
                        </>
                    )
                }
            </div>

        </>
    )
}

export default Agents