import HttpRequest from "../Miscellaneous/HttpRequest"
import Months from "../Miscellaneous/Months"
import MaritalStatus from "../Miscellaneous/MaritalStatus"
import Countries from "../Miscellaneous/Countries"
import Study from "../Miscellaneous/Study"
import { useState, useRef, useEffect } from "react";
import ModalScreen from "./ModalScreen";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import {
    TextField, FormControl, FormLabel, RadioGroup, Typography, Box, Paper,
    FormControlLabel, Radio, Select, MenuItem, InputLabel, Divider, Button,
    Table, TableBody, TableHead, TableCell, TableContainer, TableRow, Checkbox
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from "../Pages/Dashboard"
import { FetchImageSecure } from "../Miscellaneous/FetchImageSecure"


function Form(props) {

    const possibleImageExtension = ["image/jpeg", "image/png"]
    const maxImageSize = 10 * 1024 * 1024
    const minImageSize = 20 * 1024

    const { setIsSigned } = useAuth();

    let [form, setForm] = useState(null)

    let [formDuplicates, setFormDuplicates] = useState(null)

    let [upgradePrices, setUpgradePrices] = useState(null)

    let [invalidFields, setInvalidFields] = useState([])

    let thisYear = new Date().getFullYear()
    const formRef = useRef()

    let formStyle = {
        position: "absolute",
        top: "50px",
        left: 0,
        width: "100%",
        backgroundColor: "rgb(240 240 240)",
        boxSizing: "border-box",
        zIndex: 2,
        // height: "90vh",
        // minHeight: "800px",
        overflowY: "auto"
    }
    let photoStyle = {
        width: "fit-content",
        maxWidth: "100%",
        height: "fit-content",
        maxHeight: "100%"
    }
    const applicantImageLink = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
    }
    const floatPhotoStyle = {
        position: "absolute",
        left: "0px",
        top: "0px",
        width: "30%",
        // height: "30%",
        opacity: "0.7"
    }
    const duplicatePhotoStyle = {
        position: "relative",
        left: "0px",
        top: "0px",
        width: "fit-content",
        maxWidth: "100px",
        height: "fit-content",
        maxHeight: "100px"
    }



    function genderChange(val, id) {
        if (val === "M") {
            document.querySelector(`div#${id}`).style.backgroundColor = "rgb(0 143 255 / 5%)"
        } else if (val === "F") {
            document.querySelector(`div#${id}`).style.backgroundColor = "rgb(237 0 255 / 4%)"
        }
    }

    function maritalStatusChange(val) {

        if (val === "MARRIED") {
            // console.log(formRef.current.querySelector("#MAIN_LivingCountry").innerText)
            setForm((a) => {
                let newForm = { ...a }

                newForm.applicants.splice(1, 0, {
                    applicantType: "SPOUSE",
                    maritalStatus: "MARRIED",
                    children: formRef.current.querySelector(`[name="[0].Children"]`).value,
                    livingAddress: formRef.current.querySelector(`[name="[0].LivingAddress"]`).value,
                    livingCity: formRef.current.querySelector(`[name="[0].LivingCity"]`).value,
                    livingDistrict: formRef.current.querySelector(`[name="[0].LivingDistrict"]`).value,
                    livingCountry: formRef.current.querySelector(`[name="[0].LivingCountry"]`).value,
                    email: formRef.current.querySelector(`[name="[0].Email"]`).value,
                    phone1: formRef.current.querySelector(`[name="[0].Phone1"]`).value,
                    phone2: formRef.current.querySelector(`[name="[0].Phone2"]`).value
                })

                return newForm
            })
            // console.log(props.form)
        } else if (form.applicants[1].applicantType === "SPOUSE") {
            setForm((a) => {
                let newForm = { ...a }
                newForm.applicants.splice(1, 1)

                return newForm
            })
        }
    }

    function childrenChange(val) {

        const currentChildren = parseInt(formRef.current.querySelector(`[name="[0].Children"]`).value)

        setForm((prev) => {
            let newForm = { ...prev }

            if (val > currentChildren) {

                for (let i = currentChildren; i < val; i++) {
                    newForm.applicants.push({
                        applicantType: `CHILD`,
                        maritalStatus: "SINGLE",
                        children: 0,
                        livingAddress: formRef.current.querySelector(`[name="[0].LivingAddress"]`).value,
                        livingCity: formRef.current.querySelector(`[name="[0].LivingCity"]`).value,
                        livingDistrict: formRef.current.querySelector(`[name="[0].LivingDistrict"]`).value,
                        livingCountry: formRef.current.querySelector(`[name="[0].LivingCountry"]`).value,
                        email: formRef.current.querySelector(`[name="[0].Email"]`).value,
                        phone1: formRef.current.querySelector(`[name="[0].Phone1"]`).value,
                        phone2: formRef.current.querySelector(`[name="[0].Phone2"]`).value
                    })
                }

            } else if (val < currentChildren) {

                newForm.applicants.splice(newForm.applicants.length - (currentChildren - val), currentChildren - val)

            }

            return newForm
        })
    }

    async function closeForm() {

        props.setProcess(null)

        if (!["new", "search"].includes(props.process.name)) {

            props.setIsLoading(true)

            await HttpRequest(`/form/close`, {
                method: "PATCH",
                body: JSON.stringify({ formGuid: [form.guId] }),
                headers: {
                    "Content-Type": "application/json"
                }
            }, () => setIsSigned(null))

            props.setIsLoading(false)

        }

    }

    function setPreviewImage(e) {
        const fileSize = e.target.files[0].size
        const fileExtension = e.target.files[0].type

        if (fileSize > maxImageSize || fileSize < minImageSize) {
            alert("Image size not allowed. Possible size is 20kb - 10mb")
            return
        }
        if (possibleImageExtension.includes(fileExtension)) {

            const reader = new FileReader()
            reader.onload = (data) => {

                let img = formRef.current.querySelector(`img[name="${e.target.name}"]`)
                img.src = data.target.result

            }
            reader.readAsDataURL(e.target.files[0])

        }
    }


    async function get_form() {

        let path
        let options

        if (props.process.name === "new") {

            setForm({
                applicants: [{
                    applicantType: "MAIN",
                    maritalStatus: "SINGLE",
                    participation: "Y",
                    language: "EN"
                }]
            })

        } else if (props.process.name === "search") {

            path = `/form/${props.process.formGuId}`
            options = {
                method: "GET"
            }

        } else {
            path = `/form/process/${props.process.name}`
            options = {
                method: "GET"
            }
        }


        if (props.process.name !== "new") {

            props.setIsLoading(true)
            let responseBody = await HttpRequest(path, options, () => setIsSigned(null)).catch(e => null)

            if (responseBody?.statusText === "OK") {
                for (let applicant of responseBody.data.form.applicants) {

                    if (applicant.photoLink || applicant.originalPhotoLink) {
                        applicant.imageOriginal = URL.createObjectURL(await FetchImageSecure(applicant.originalPhotoLink))

                        applicant.image = applicant.photoLink ?
                            URL.createObjectURL(await FetchImageSecure(applicant.photoLink || applicant.originalPhotoLink)) :
                            applicant.imageOriginal
                    } else {

                        applicant.imageOriginal = null
                        applicant.image = null
                    }

                }
                if (responseBody.data.duplicates) {
                    for (let form of responseBody.data.duplicates) {
                        for (let applicant of form.applicants) {
                            if (applicant.photoLink || applicant.originalPhotoLink) {
                                applicant.image = URL.createObjectURL(await FetchImageSecure(applicant.photoLink || applicant.originalPhotoLink))
                            } else {
                                applicant.image = null
                            }
                        }
                    }
                    setFormDuplicates(responseBody.data.duplicates)
                }
                setForm(responseBody.data.form)
                setUpgradePrices(responseBody.data?.upgradePrices)
            } else {
                props.setProcess(null)
                props.setNotification(responseBody.data?.msg || responseBody.statusText || "No form left")
            }

            props.setIsLoading(false)

        }

    }

    async function submit_form(nextStep) {

        let formData = new FormData(formRef.current)
        let path = ""
        let options = {}

        for (let input of formData.entries()) {
            if (input[0].includes("incorrect") && input[1] === "on") {
                formData.set(input[0], "true")
            }
        }


        if (props.process.name === 'new') {
            path = "/form"

            options["method"] = "POST"
            options["body"] = formData
        } else if (["sort", "call", "final_check", "search"].includes(props.process.name)) {
            path = `/form?openedAs=${props.process.name}&nextStep=${nextStep}`

            options["method"] = "PUT"
            options["body"] = formData
        }


        if (nextStep === "refused" && !formRef.current.querySelector(`[name="Comment"]`).value) {
            alert("Write refuse reason in comment")
            return
        } else if (nextStep === "delay") {
            formData.set("delay", new Date(formRef.current.querySelector('[name="delay"]').value).toISOString())
        }

        props.setIsLoading(true)
        let response = await HttpRequest(path, options, () => setIsSigned(null)).catch(e => false)
        props.setIsLoading(false)

        if (response?.statusCode.toString()[0] === "2") {
            const process = props.process

            props.setNotification("Success")
            props.setProcess(null)

            if (process.name !== "search") {
                setTimeout(() => {
                    props.setProcess(process)
                }, 0)
            }

        } else if (response?.statusCode === 400) {
            if (response?.data?.errors) {
                setInvalidFields(Object.keys(response.data.errors))
                formRef.current.querySelector(`[name="${Object.keys(response.data.errors)[0]}"]`).scrollIntoView({ behavior: "smooth" })
            }
            response.data?.msg && props.setNotification(response.data.msg)
        } else {
            props.setNotification(response?.statusText || "Error occurred")
        }

    }

    async function send_invoice() {

        const payment = document.querySelector("input[name=upgradePrice]").value

        props.setIsLoading(true)

        let response = await HttpRequest(
            `/invoice?price=${payment.split("_")[1]}&paymentType=${payment.split("_")[0]}&formGuId=${form.guId}`,
            {
                method: "POST"
            }, () => setIsSigned(null)).catch(e => false)

        props.setIsLoading(false)


        if (response?.statusCode.toString()[0] === "2") {
            for (let applicant of response.data.form.applicants) {

                if (applicant.photoLink || applicant.originalPhotoLink) {
                    applicant.imageOriginal = URL.createObjectURL(await FetchImageSecure(applicant.originalPhotoLink || null).catch(e => false))

                    applicant.image = applicant.photoLink ?
                        URL.createObjectURL(await FetchImageSecure(applicant.photoLink || applicant.originalPhotoLink).catch(e => false)) :
                        applicant.imageOriginal
                }

            }
            setForm({ ...response.data.form })
        } else {
            props.setNotification(response?.data?.msg || response?.statusText)
        }

    }

    function formChange(e) {

        if (e.target?.name && invalidFields.includes(e.target.name)) {
            setInvalidFields(() => {
                let newFields = [...invalidFields]

                newFields.splice(invalidFields.indexOf(e.target.name), 1)

                return newFields
            })

        }
    }

    async function return_photo(applicantId) {

        props.setIsLoading(true)
        let response = await HttpRequest(
            `/form/return-photo?formGuid=${form.guId}&applicantId=${applicantId}`,
            { method: "PATCH" },
            () => setIsSigned(null)
        ).catch(e => false)
        props.setIsLoading(false)


        if (response?.statusCode.toString()[0] === "2") {
            let process = props.process
            props.setProcess(null)

            setTimeout(() => {
                props.setProcess(process)
            }, 0)
        } else {
            props.setNotification(response?.data?.msg || response?.statusText)
        }
    }

    async function mark_duplicate(formGuId) {

        let confirmation = window.confirm("Are you sure?")

        if (confirmation) {
            props.setIsLoading(true)
            let response = await HttpRequest(`/form/duplicate/${formGuId}`, { method: "POST" }, () => props.setIsSigned(null)).catch(e => false)

            if (response.statusText === "OK") {

                let deleteIndex = formDuplicates.findIndex(form => form.guId === formGuId)

                if (deleteIndex !== -1) {
                    setFormDuplicates(prev => {
                        let newArr = [...prev]
                        newArr.splice(deleteIndex, 1)

                        return newArr
                    })
                } else {
                    let process = props.process
                    props.setProcess(null)
                    setTimeout(() => {
                        props.setProcess(process)
                    })
                }

            } else {
                props.setNotification(response.data?.msg || response.StatusText || "Unknown error")
            }

            props.setIsLoading(false)
        }

    }

    async function atachApplicant(newApplicantType) {
        let confirmation = window.confirm(`Add ${newApplicantType}???`)

        if (confirmation) {
            if (newApplicantType === "SPOUSE" && form.applicants.some(a => a.applicantType === newApplicantType))
                props.setNotification("Form already contain spouse applicant")
            else {

                props.setIsLoading(true)

                let response = await HttpRequest(
                    `/form/attach-applicant?applicantType=${newApplicantType}&formGuId=${form.guId}`,
                    { method: "POST" },
                    () => { props.setIsSigned(null) }
                ).catch(e => false)

                if (response.statusCode === 201) {
                    for (let applicant of response.data.form.applicants) {

                        if (applicant.photoLink || applicant.originalPhotoLink) {
                            applicant.imageOriginal = URL.createObjectURL(await FetchImageSecure(applicant.originalPhotoLink))

                            applicant.image = applicant.photoLink ?
                                URL.createObjectURL(await FetchImageSecure(applicant.photoLink || applicant.originalPhotoLink)) :
                                applicant.imageOriginal
                        } else {

                            applicant.imageOriginal = null
                            applicant.image = null
                        }

                    }
                    setForm(response.data.form)
                } else {
                    props.setNotification(response.data?.msg || "Unable to add applicant")
                }

                props.setIsLoading(false)

            }
        } else {
            props.setNotification("God bless you!")
        }

    }



    useEffect(() => {

        if (form?.applicants) {
            for (let applicant of form.applicants) {
                if (applicant.gender) genderChange(applicant.gender, applicant.applicantType + applicant.personNumber)
            }
        }

    }, [form])


    useEffect(() => {

        get_form()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div >

            {form?.applicants &&
                <>
                    <ModalScreen zindex={2}></ModalScreen>
                    <form style={formStyle} ref={formRef} encType="multipart/form-data" onChange={formChange}>

                        {/* {isLoading && <EmbedLoading><CircularProgress size={"lg"} /></EmbedLoading>} */}


                        <CloseIcon fontSize={"large"}
                            style={{ position: "absolute", right: "0px", padding: "10px", cursor: "pointer" }}
                            onClick={closeForm} />
                        <TextField variant="standard" name={`formGuid`}
                            style={{ visibility: "hidden", display: "block", height: "0px" }}
                            value={form.guId || ""} />

                        {form.applicants.map((applicant, index) => (
                            <div key={applicant.applicantType + (applicant.personNumber || index)}
                                id={applicant.applicantType + (applicant.personNumber || index)}
                                style={{ padding: "25px" }}
                            >

                                {applicant.id && (
                                    <TextField variant="standard" name={`[${index}].Id`}
                                        style={{ visibility: "hidden", display: "block", height: "0px" }}
                                        value={applicant.id || ""} />
                                )}
                                <TextField variant="standard" name={`[${index}].PersonNumber`}
                                    style={{ visibility: "hidden", display: "block", height: "0px" }}
                                    value={applicant.personNumber || index} />
                                <TextField variant="standard" name={`[${index}].ApplicantType`}
                                    style={{ visibility: "hidden", display: "block", height: "0px" }}
                                    value={applicant.applicantType} />



                                <Typography variant="h5">{applicant.applicantType}</Typography>
                                <Grid2 container>
                                    <Grid2 md={props.process.name === "final_check" ? 4 : 3}>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            {props.process.name === "final_check" && (
                                                <div style={{
                                                    width: "180px",
                                                    height: "180px",
                                                    border: invalidFields.includes(`[${applicant.id || index}].Photo`) ? "2px solid red" : "1px solid black"
                                                }}>
                                                    <a style={applicantImageLink} target="_blank" rel="noreferrer"
                                                        href={applicant.imageOriginal}
                                                    >
                                                        <img src={applicant.imageOriginal || "/images/profile.png"}
                                                            style={photoStyle} name={`[${applicant.id || index}].Photo`} alt="" />
                                                    </a>
                                                </div>
                                            )}

                                            <div style={{
                                                position: "relative",
                                                width: "180px",
                                                height: "180px",
                                                border: invalidFields.includes(`[${applicant.id || index}].Photo`) || applicant.incorrectPhoto ? "2px solid red" : "1px solid black"
                                            }}>
                                                <a style={applicantImageLink} target="_blank" rel="noreferrer"
                                                    href={applicant.image || "/images/profile.png"}
                                                >
                                                    {props.process.name === "search" && (
                                                        <img style={floatPhotoStyle} src={applicant.imageOriginal} alt="applicant" />
                                                    )}
                                                    <img src={applicant.image || "/images/profile.png"}
                                                        style={photoStyle}
                                                        name={`[${applicant.id || index}].Photo`} alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>

                                        {props.process.name !== "final_check" && (
                                            <>
                                                <InputLabel htmlFor={`[${applicant.id || index}].Photo`} style={{
                                                    color: "white",
                                                    backgroundColor: "#039dfc",
                                                    width: "fit-content",
                                                    padding: "5px",
                                                    cursor: "pointer"
                                                }}>Upload</InputLabel>
                                                <div>
                                                    <Checkbox
                                                        name={`[${index}].incorrectPhoto`}
                                                        defaultChecked={applicant.incorrectPhoto}
                                                        onChange={(e) => { e.target.value = e.target.checked }}
                                                        defaultValue={applicant.incorrectPhoto}
                                                    />
                                                    <span>Bad photo</span>
                                                </div>

                                            </>

                                        )}
                                        <input type="file" id={`[${applicant.id || index}].Photo`} name={`[${applicant.id || index}].Photo`}
                                            style={{ display: "none" }} onChange={(event) => setPreviewImage(event)} />
                                    </Grid2>
                                    <Grid2 md={props.process.name === "final_check" ? 4 : 5}>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Step:</Typography>
                                            <Typography variant="body1">
                                                <b style={{ color: "orange" }}>{form.step}</b> / &nbsp;
                                                <b style={form.status === "GOOD" ?
                                                    { color: "green" } :
                                                    { color: "red" }}>
                                                    {form.status}
                                                </b>
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Id:</Typography>
                                            <Typography variant="body1">{form.guId}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Source:</Typography>
                                            <Typography variant="body1">{form.source}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Language:</Typography>
                                            <Typography variant="body1">{form.language}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Payment type:</Typography>
                                            <Typography variant="body1">
                                                {form.payments?.[0].paymentType || ""} /&nbsp;
                                                {form.payments?.[0].paymentAmount || ""}$ /
                                                {form.payments?.[0].paidParticipationTimes || ""} years
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Payment status:</Typography>
                                            <Typography variant="body1">{form.payments?.[0].paymentStatus || ""}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Paid Participation left:</Typography>
                                            <Typography variant="body1">{form.payments?.[0].paidParticipationLeft || ""}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Agent:</Typography>
                                            <Typography variant="body1">{form.agentId}</Typography>
                                        </div>
                                    </Grid2>
                                    <Grid2 md={4}>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Operator:</Typography>
                                            <Typography variant="body1">{form.operator}</Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Added by:</Typography>
                                            <Typography variant="body1">
                                                {form.addBy}&nbsp;
                                                {form.createdAt ? new Date(form.createdAt + "Z").toLocaleString() : ""}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Sort by:</Typography>
                                            <Typography variant="body1">
                                                {applicant.sortBy}&nbsp;
                                                {applicant.sortDate ? new Date(applicant.sortDate + "Z").toLocaleString() : ""}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Call by:</Typography>
                                            <Typography variant="body1">
                                                {applicant.callBy}&nbsp;
                                                {applicant.callDate ? new Date(applicant.callDate + "Z").toLocaleString() : ""}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Photo edit by:</Typography>
                                            <Typography variant="body1">
                                                {applicant.photoEditBy}&nbsp;
                                                {applicant.photoEditDate ? new Date(applicant.photoEditDate + "Z").toLocaleString() : ""}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>Final check by:</Typography>
                                            <Typography variant="body1">
                                                {applicant.finalCheckBy} &nbsp;
                                                {applicant.finalCheckDate ? new Date(applicant.finalCheckDate + "Z").toLocaleString() : ""}
                                            </Typography>
                                        </div>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            <Typography variant="body1" fontWeight={"600"}>DV registration at:</Typography>
                                            <Typography variant="body1">
                                                {applicant.dvRegistrationDate ? new Date(applicant.dvRegistrationDate + "Z").toLocaleString() : ""}
                                            </Typography>
                                        </div>
                                    </Grid2>
                                    {props.process.name === "final_check" && (
                                        <Grid2 md={3}>
                                            <Button variant="contained" color={"error"} onClick={() => return_photo(applicant.id)}>Return photo to edit</Button>
                                        </Grid2>
                                    )}
                                </Grid2>


                                <Divider style={{ marginTop: "20px" }} />


                                <Box style={{ marginTop: "25px", marginBottom: "10px" }}>
                                    <Typography variant="h5">Personal</Typography>
                                </Box>
                                <Grid2 container rowSpacing={3} columnSpacing={6} alignItems="end">
                                    <Grid2 md={3}>
                                        <TextField label="Last Name" variant="standard" name={`[${index}].LastName`}
                                            defaultValue={applicant.lastName} fullWidth error={invalidFields.includes(`[${index}].LastName`)}
                                        />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="First Name" variant="standard" name={`[${index}].FirstName`}
                                            defaultValue={applicant.firstName || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].FirstName`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <FormControl size={"small"}>
                                            <FormLabel id="demo-radio-buttons-group-label"
                                                error={invalidFields.includes(`[${index}].Gender`)}>Gender</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name={`[${index}].Gender`}
                                                defaultValue={applicant.gender || false}
                                                onChange={(event) => genderChange(event.target.value, applicant.applicantType + (applicant.personNumber || index))}
                                            >
                                                <FormControlLabel value="F" control={<Radio size={"small"} />} label="Female" />
                                                <FormControlLabel value="M" control={<Radio size={"small"} />} label="Male" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <FormControl size={"small"}>
                                            <FormLabel id="demo-radio-buttons-group-label"
                                                error={invalidFields.includes(`[${index}].Participation`)}>Participation</FormLabel>
                                            <RadioGroup row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name={`[${index}].Participation`}
                                                defaultValue={applicant.participation || false}
                                            >
                                                <FormControlLabel value="Y" control={<Radio size={"small"} />} label="Y" />
                                                <FormControlLabel value="N" control={<Radio size={"small"} />} label="N"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container rowSpacing={3} columnSpacing={6}>
                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].BirthDate`)}>Birth Date</InputLabel>
                                            <Select
                                                defaultValue={applicant.birthDate || ""}
                                                label="BirthDate"
                                                name={`[${index}].BirthDate`}
                                                error={invalidFields.includes(`[${index}].BirthDate`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                {[...Array(32).keys()].map((val, index) => val !== 0 && <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].BirthMonth`)}>Birth Month</InputLabel>
                                            <Select
                                                defaultValue={applicant.birthMonth || ""}
                                                label="BirthMonth"
                                                name={`[${index}].BirthMonth`}
                                                error={invalidFields.includes(`[${index}].BirthMonth`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                {Months.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].BirthYear`)}>Birth Year</InputLabel>
                                            <Select
                                                defaultValue={applicant.birthYear || ""}
                                                label="BirthYear"
                                                name={`[${index}].BirthYear`}
                                                error={invalidFields.includes(`[${index}].BirthYear`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                {[...Array(100).keys()].map((val, index) => <MenuItem key={index} value={thisYear - val}>{thisYear - val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>


                                <Box style={{ marginTop: "25px", marginBottom: "10px" }}>
                                    <Typography variant="h5">Address</Typography>
                                </Box>
                                <Grid2 container rowSpacing={3} columnSpacing={6} alignItems="end">
                                    <Grid2 md={3}>
                                        <TextField label="Birth City" variant="standard" name={`[${index}].BirthCity`}
                                            defaultValue={applicant.birthCity} fullWidth
                                            error={invalidFields.includes(`[${index}].BirthCity`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="Birth District" variant="standard" name={`[${index}].BirthDistrict`}
                                            defaultValue={applicant.birthDistrict || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].BirthDistrict`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].BirthCountry`)}>Birth Country</InputLabel>
                                            <Select
                                                defaultValue={applicant.birthCountry || ""}
                                                label="BirthCountry"
                                                name={`[${index}].BirthCountry`}
                                                error={invalidFields.includes(`[${index}].BirthCountry`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Choose Country</MenuItem>
                                                {Countries.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container rowSpacing={3} columnSpacing={6} alignItems="end">
                                    <Grid2 md={3}>
                                        <TextField label="Living Address" variant="standard" name={`[${index}].LivingAddress`}
                                            defaultValue={applicant.livingAddress || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].LivingAddress`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="Living City" variant="standard" name={`[${index}].LivingCity`}
                                            defaultValue={applicant.livingCity || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].LivingCity`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="Living District" variant="standard" name={`[${index}].LivingDistrict`}
                                            defaultValue={applicant.livingDistrict || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].LivingDistrict`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].LivingCountry`)}>Living Country</InputLabel>
                                            <Select
                                                defaultValue={applicant.livingCountry || ""}
                                                label="LivingCountry"
                                                name={`[${index}].LivingCountry`}
                                                error={invalidFields.includes(`[${index}].LivingCountry`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Choose Country</MenuItem>
                                                {Countries.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>


                                <Box style={{ marginTop: "25px", marginBottom: "10px" }}>
                                    <Typography variant="h5">Contacts</Typography>
                                </Box>
                                <Grid2 container rowSpacing={3} columnSpacing={6} alignItems="end">
                                    <Grid2 md={3}>
                                        <TextField label="Email" variant="standard" name={`[${index}].Email`}
                                            defaultValue={applicant.email || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].Email`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="Phone1" variant="standard" name={`[${index}].Phone1`}
                                            defaultValue={applicant.phone1 || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].Phone1`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="Phone2" variant="standard" name={`[${index}].Phone2`}
                                            defaultValue={applicant.phone2 || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].Phone2`)} />
                                    </Grid2>
                                </Grid2>


                                <Box style={{ marginTop: "25px", marginBottom: "10px" }}>
                                    <Typography variant="h5">Passport</Typography>
                                </Box>
                                <Grid2 container rowSpacing={3} columnSpacing={6} alignItems="end">
                                    <Grid2 md={3}>
                                        <TextField label="Passport Nr" variant="standard"
                                            name={`[${index}].PassportNumber`}
                                            defaultValue={applicant.passportNumber || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].PassportNumber`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <TextField label="Passport authority" variant="standard"
                                            name={`[${index}].PassportAuthority`}
                                            defaultValue={applicant.passportAuthority || ""} fullWidth
                                            error={invalidFields.includes(`[${index}].PassportAuthority`)} />
                                    </Grid2>
                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportCountry`)}>Passport country</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportCountry || ""}
                                                label="PassportCountry"
                                                name={`[${index}].PassportCountry`}
                                                error={invalidFields.includes(`[${index}].PassportCountry`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Choose Country</MenuItem>
                                                {Countries.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container rowSpacing={3} columnSpacing={6}>
                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportIssueDate`)}>Passport issue date</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportIssueDate || ""}
                                                label="PassportIssueDate"
                                                name={`[${index}].PassportIssueDate`}
                                                error={invalidFields.includes(`[${index}].PassportIssueDate`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Issue date</MenuItem>
                                                {[...Array(32).keys()].map((val, index) => val !== 0 && <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportIssueMonth`)}>Passport issue month</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportIssueMonth || ""}
                                                label="PassportIssueMonth"
                                                name={`[${index}].PassportIssueMonth`}
                                                error={invalidFields.includes(`[${index}].PassportIssueMonth`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Issue month</MenuItem>
                                                {Months.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportIssueYear`)}>Passport issue year</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportIssueYear || ""}
                                                label="PassportIssueYear"
                                                name={`[${index}].PassportIssueYear`}
                                                error={invalidFields.includes(`[${index}].PassportIssueYear`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Issue year</MenuItem>
                                                {[...Array(20).keys()].map((val, index) => <MenuItem key={index} value={thisYear - val}>{thisYear - val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>
                                <Grid2 container rowSpacing={3} columnSpacing={6}>
                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportExpireDate`)}>Passport exp date</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportExpireDate || ""}
                                                label="PassportExpireDate"
                                                name={`[${index}].PassportExpireDate`}
                                                error={invalidFields.includes(`[${index}].PassportExpireDate`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Expire date</MenuItem>
                                                {[...Array(32).keys()].map((val, index) => val !== 0 && <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportExpireMonth`)}>Passport exp month</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportExpireMonth || ""}
                                                label="PassportExpireMonth"
                                                name={`[${index}].PassportExpireMonth`}
                                                error={invalidFields.includes(`[${index}].PassportExpireMonth`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Expire month</MenuItem>
                                                {Months.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].PassportExpireYear`)}>Passport exp year</InputLabel>
                                            <Select
                                                defaultValue={applicant.passportExpireYear || ""}
                                                label="PassportExpireYear"
                                                name={`[${index}].PassportExpireYear`}
                                                error={invalidFields.includes(`[${index}].PassportExpireYear`)}
                                                onChange={(e) => formChange(e)}
                                            >
                                                <MenuItem value="">Expire year</MenuItem>
                                                {[...Array(20).keys()].map((val, index) => <MenuItem key={index} value={thisYear + val}>{thisYear + val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>
                                </Grid2>


                                <Box style={{ marginTop: "25px", marginBottom: "10px" }}>
                                    <Typography variant="h5">Achievements</Typography>
                                </Box>
                                <Grid2 container rowSpacing={3} columnSpacing={6}>
                                    <Grid2 md={3} display={"flex"} alignItems={"end"}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].MaritalStatus`)}>Marital status</InputLabel>
                                            <Select
                                                defaultValue={applicant.maritalStatus || ""}
                                                label="MaritalStatus"
                                                name={`[${index}].MaritalStatus`}
                                                readOnly={applicant.applicantType !== "MAIN" || props.process.name !== "new"}
                                                onChange={(event) => maritalStatusChange(event.target.value)}
                                                error={invalidFields.includes(`[${index}].MaritalStatus`)}
                                            >
                                                {MaritalStatus.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {applicant.applicantType === "MAIN" && (
                                            <Checkbox
                                                name={`[${index}].incorrectMaritalStatus`}
                                                defaultChecked={applicant.incorrectMaritalStatus}
                                                onChange={(e) => { e.target.value = e.target.checked }}
                                                defaultValue={applicant.incorrectMaritalStatus}
                                            />
                                        )}
                                    </Grid2>

                                    <Grid2 md={3}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].Study`)}>Study</InputLabel>
                                            <Select
                                                defaultValue={applicant.study || ""}
                                                label="Study"
                                                name={`[${index}].Study`}
                                                onChange={(e) => formChange(e)}
                                                error={invalidFields.includes(`[${index}].Study`)}
                                            >
                                                {Study.map((val, index) => <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid2>

                                    <Grid2 md={3} display={"flex"} alignItems={"end"}>
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel error={invalidFields.includes(`[${index}].Children`)}>Children</InputLabel>
                                            <Select
                                                defaultValue={applicant.children || 0}
                                                label="Children"
                                                name={`[${index}].Children`}
                                                readOnly={applicant.applicantType !== "MAIN" || props.process.name !== "new"}
                                                onChange={(e) => childrenChange(e.target.value)}
                                                error={invalidFields.includes(`[${index}].Children`)}
                                            >
                                                {[...Array(16).keys()].map((val, index) =>
                                                    <MenuItem key={index} value={val}>{val}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {applicant.applicantType === "MAIN" && (
                                            <Checkbox
                                                name={`[${index}].incorrectChildren`}
                                                defaultChecked={applicant.incorrectChildren}
                                                onChange={(e) => { e.target.value = e.target.checked }}
                                                defaultValue={applicant.incorrectChildren}
                                            />
                                        )}
                                    </Grid2>
                                </Grid2>
                            </div>)
                        )}

                        {formDuplicates?.map(form => (
                            <div key={form.guId} style={{ marginTop: "50px", border: "2px solid red" }}>
                                {form.applicants.map((applicant, index) => (
                                    <Grid2 container key={index} style={{
                                        backgroundColor: applicant.gender === "M" ? "rgb(0 143 255 / 5%)" : "rgb(237 0 255 / 4%)"
                                    }}>
                                        <Grid2 md={2} display={"flex"} alignItems={"center"} justifyContent={"start"}>
                                            <img style={duplicatePhotoStyle} src={applicant.image} alt="applicant" />
                                        </Grid2>

                                        <Grid2 md={1} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>FirstName</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.firstName}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>LastName</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.lastName}</Typography>
                                        </Grid2>

                                        <Grid2 md={1} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Birth date</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>
                                                {new Date(applicant.birthDateTime).toLocaleDateString()}
                                            </Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Marital status</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.maritalStatus}</Typography>
                                        </Grid2>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Email</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.email}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Phone</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{applicant.phone1}</Typography>
                                        </Grid2>

                                        <Grid2 md={1} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Status</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0} fontWeight={"bold"}
                                                color={form.status !== "GOOD" ? "OrangeRed" : "LimeGreen "}>{form.status}</Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Children</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{form.children}</Typography>
                                        </Grid2>

                                        <Grid2 md={1} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Payment</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}
                                                fontWeight={form.payments[0].paymentType !== "FREE" ? "bold" : ""}
                                                color={form.payments[0].paymentType !== "FREE" ? "#12a0be" : "black"}>
                                                {form.payments[0].paymentType}
                                            </Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Amount</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>
                                                {form.payments[0].paymentAmount} / {form.payments[0].paymentStatus}
                                            </Typography>
                                        </Grid2>

                                        <Grid2 md={2} style={{ display: "flex", flexDirection: "column" }}>
                                            <Typography fontSize={"small"} fontWeight={"bold"}>Step</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0} color={"DeepSkyBlue"} fontWeight={"bold"}>
                                                {form.step}
                                            </Typography>

                                            <Typography fontSize={"small"} fontWeight={"bold"}>Blocked</Typography>
                                            <Typography fontSize={"small"} spacing={0} margin={0}>{form.isBlocked ? "Yes" : "No"}</Typography>
                                        </Grid2>

                                        <Grid2 md={2}>
                                            <Button color="error" variant="contained" onClick={() => mark_duplicate(form.guId)}>
                                                Mark as duplicate
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                ))}
                            </div>
                        ))}
                        {formDuplicates?.length > 0 && (
                            <Grid2 container marginTop={"20px"}>
                                <Grid2 md={12} display={"flex"} justifyContent={"center"}>
                                    <Button color="error" variant="contained" onClick={() => mark_duplicate(form.guId)}>
                                        Mark as duplicate
                                    </Button>
                                </Grid2>
                            </Grid2>
                        )}

                        <div style={{ marginTop: 30 }}>
                            <TableContainer component={Paper} >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{ tableLayout: "fixed", wordWrap: "break-word" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={100}>User</TableCell>
                                            <TableCell align="left" width={150}>Date</TableCell>
                                            <TableCell align="left">Comment</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="comment">

                                            </TableCell>
                                            <TableCell component="th" scope="comment">

                                            </TableCell>
                                            <TableCell align="left">
                                                <textarea name="Comment" maxLength="254" style={{
                                                    minHeight: 50,
                                                    maxWidth: "99%",
                                                    minWidth: "99%",
                                                    width: "99%",
                                                    border: "1px solid #e1e1e1",
                                                    display: "block"
                                                }}>

                                                </textarea>
                                            </TableCell>
                                        </TableRow>
                                        {form.comments?.map((comment) => (
                                            <TableRow
                                                key={comment.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="comment">
                                                    <b>{comment.user}</b>
                                                </TableCell>
                                                <TableCell component="th" scope="comment">
                                                    <b>{new Date(comment.date.replace("Z", "") + "Z").toLocaleString()}</b>
                                                </TableCell>
                                                <TableCell align="left">{comment.text}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>


                        {
                            upgradePrices && ["call", "search"].includes(props.process.name) && (
                                <Grid2 container marginTop={"70px"} marginBottom={"20px"} justifyContent={"center"}>
                                    <Grid2 md={3} display={"flex"}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <InputLabel>Choose upgrade price</InputLabel>
                                            <Select
                                                defaultValue={""}
                                                label="UpgradePriceaaaa"
                                                name="upgradePrice"
                                                id="upgradePrice"
                                            >
                                                {
                                                    Object.keys(upgradePrices).map((key, index) => {
                                                        return upgradePrices[key].map((price, index) => {
                                                            switch (index) {
                                                                case 0:
                                                                    index = 1
                                                                    break
                                                                case 1:
                                                                    index = 3
                                                                    break
                                                                case 2:
                                                                    index = 5
                                                                    break
                                                                default:
                                                                    break
                                                            }
                                                            return <MenuItem key={`${key}_${price}`} value={`${key}_${price}`}>
                                                                {key} {index}_years - {price}$
                                                            </MenuItem>
                                                        })
                                                    })
                                                }
                                            </Select>

                                        </FormControl>
                                    </Grid2>


                                    <Button color={"success"} variant="contained" onClick={() => send_invoice()}>
                                        Send invoice
                                    </Button>
                                </Grid2>
                            )
                        }
                        <Grid2 container marginTop={"50px"} marginBottom={"20px"}>
                            <Grid2 md={12} display={"flex"} alignItems={"center"} flexDirection={"column"} gap={"50px"}>
                                {
                                    props.process.name === "new" && (
                                        <Button variant="contained" onClick={submit_form}>Add</Button>
                                    )
                                }
                                {
                                    props.process.name === "sort" && (
                                        <div style={{ display: "flex", gap: "100px" }}>
                                            <Button color={"warning"} variant="contained" onClick={() => submit_form("call")}>Call</Button>
                                            <Button color={"success"} variant="contained" onClick={() => submit_form("photo_edit")}>
                                                Send to PHOTO_EDIT
                                            </Button>
                                        </div>
                                    )
                                }
                                {
                                    props.process.name === "call" && (
                                        <div style={{ display: "flex", gap: "100px", alignItems: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <TextField type="datetime-local" placeholder="a" variant="standard"
                                                    name="delay" />
                                                <Button variant="contained" onClick={() => submit_form("delay")}>Delay</Button>
                                            </div>

                                            <Button color={"error"} variant="contained" onClick={() => submit_form("refused")}>Refuse</Button>
                                            <Button color={"success"} variant="contained" onClick={() => submit_form("photo_edit")}>
                                                Send to PHOTO_EDIT
                                            </Button>
                                        </div>
                                    )
                                }
                                {
                                    props.process.name === "final_check" && (
                                        <div style={{ display: "flex", gap: "100px", alignItems: "center" }}>
                                            <Button color={"warning"} variant="contained" onClick={() => submit_form("RE_CALL")}>
                                                Re-call
                                            </Button>
                                            <Button color={"success"} variant="contained" onClick={() => submit_form("WAITING_REGISTRATION")}>
                                                Send to registration
                                            </Button>
                                        </div>
                                    )
                                }
                                {
                                    props.process.name === "search" && (
                                        <>
                                            <div style={{ display: "flex", gap: "100px", alignItems: "center" }}>
                                                <Button color={"warning"} variant="contained" onClick={() => atachApplicant("SPOUSE")}>
                                                    Add spouse
                                                </Button>
                                                <Button color={"warning"} variant="contained" onClick={() => atachApplicant("CHILD")}>
                                                    Add child
                                                </Button>
                                            </div>
                                            <div style={{ display: "flex", gap: "100px", alignItems: "center" }}>
                                                <Button color={"error"} variant="contained" onClick={() => submit_form("refused")}>
                                                    Refuse
                                                </Button>
                                                <Button variant="contained" onClick={() => submit_form("RE_SORT")}>
                                                    Re - sort
                                                </Button>
                                            </div>
                                        </>
                                    )
                                }
                            </Grid2>
                        </Grid2>
                    </form>
                </>
            }
        </div>
    )
}

export default Form