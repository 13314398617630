import ModalScreen from "./ModalScreen"
import { useState, useEffect } from "react"
import CloseIcon from '@mui/icons-material/Close';
import HttpRequest from "../Miscellaneous/HttpRequest";
import { useAuth } from "../Pages/Dashboard";
import { FetchImageSecure } from "../Miscellaneous/FetchImageSecure";



function PhotoEditForm(props) {

    const formStyle = {
        position: "absolute",
        top: "50px",
        left: "0px",
        width: "100%",
        backgroundColor: "rgb(216 216 216)",
        boxSizing: "border-box",
        zIndex: 2,
        height: "90vh",
        minHeight: "800px",
        overflowY: "auto"
    }
    const photoBlockStyle = {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "200px",
        height: "200px",
        border: "1px solid black",
        backgroundColor: "white"
    }
    const photoStyle = {
        width: "fit-content",
        maxWidth: "100%",
        height: "fit-content",
        maxHeight: "100%"
    }
    const floatPhotoStyle = {
        position: "absolute",
        left: "0px",
        top: "0px",
        width: "30%",
        // height: "30%",
        opacity: "0.7"
    }
    const downloadOriginalPhotoButton = {
        display: "block",
        marginTop: "5px",
        color: "red",
        backgroundColor: "white",
        cursor: "pointer",
        border: "1px solid gray"
    }
    const downloadAllButton = {
        display: "block",
        color: "white",
        backgroundColor: "#57c7ff",
        border: "1px solid gray",
        fontSize: "18px",
        cursor: "pointer",
        minHeight: "26px"
    }


    const possibleImageExtension = ["image/jpeg", "image/jpg"]
    const maxImageSize = 150 * 1024 // 100 kb
    const minImageSize = 20 * 1024 // 20 kb


    let { setIsSigned } = useAuth()

    let [forms, setForms] = useState(null)


    async function get_forms_with_photo() {
        const path = `/form/process/${props.process.name}`
        const options = {
            method: "GET"
        }

        props.setIsLoading(true)
        let rs = await HttpRequest(path, options, () => setIsSigned(null)).catch(e => null)


        if (rs?.statusText === "OK") {
            for (let form of rs.data) {
                for (let applicant of form.applicants) {

                    applicant.image = URL.createObjectURL(await FetchImageSecure(applicant.photoLink || applicant.originalPhotoLink))
                    if (applicant.photoLink) {
                        applicant.imageOriginal = URL.createObjectURL(await FetchImageSecure(applicant.originalPhotoLink))
                    }
                }
            }
            setForms(rs.data)
        } else {
            props.setProcess(null)
            props.setNotification(rs?.statusText || "No form left")
        }
        props.setIsLoading(false)
    }

    async function download_all_photo() {
        for (let form of forms) {
            for (let applicant of form.applicants) {

                let fileName = (applicant.photoLink || applicant.originalPhotoLink).split("/").reverse()[0]
                download_image(applicant.photoLink || applicant.originalPhotoLink, fileName, form.guId)

                await new Promise((resolve) => {
                    setTimeout(() => {
                        resolve()
                    }, 1000)
                })

            }
        }
    }

    async function download_image(url, fileName, guId) {

        let blob = await HttpRequest(url.substring(url.indexOf("/files")), {}).catch(e => false)
        console.log(blob)
        let file = new FileReader()
        file.readAsDataURL(blob.data)
        file.onload = function (data) {

            const link = document.createElement("a")
            link.setAttribute("href", data.target.result)
            link.setAttribute("download", `${guId}@${fileName}`)
            link.click()
        }


        // fetch(url, {headers: {"authorization": window.localStorage}})
        //     .then(response => response.blob())
        //     .then(blob => {

        //         let file = new FileReader()
        //         file.readAsDataURL(blob)
        //         file.onload = function (data) {

        //             const link = document.createElement("a")
        //             link.setAttribute("href", data.target.result)
        //             link.setAttribute("download", `${guId}@${fileName}`)
        //             link.click()
        //         }

        //     })
        //     .catch(e => {
        //         console.log(e)
        //     })
    }

    function upload_all_photo(e) {

        let incorrectFiles = ""

        for (let file of e.target.files) {

            if (
                file.name.includes("@") &&
                possibleImageExtension.includes(file.type) &&
                file.size < maxImageSize &&
                file.size > minImageSize
            ) {
                let imgId = "_" + file.name.substring(0, file.name.indexOf(".")).split("@")[1].split("_")[0]

                let blobFile = new FileReader()
                blobFile.readAsDataURL(file)
                blobFile.onload = function (data) {
                    document.querySelector(`#${imgId}`).setAttribute("src", data.target.result)


                    document.querySelector(`#${imgId}`).onload = function () {

                        let w = parseInt(document.querySelector(`#${imgId}`).naturalWidth)
                        let h = parseInt(document.querySelector(`#${imgId}`).naturalHeight)

                        document.querySelector(`#wh${imgId}`).innerText = `W x H: ${w} x ${h}`

                        if (w === h && w >= 600 && w <= 1200) {
                            document.querySelector(`#${imgId}`).parentElement.style.border = "3px solid #00eb00"
                        } else {
                            document.querySelector(`#${imgId}`).parentElement.style.border = "3px solid red"
                        }

                    }

                    document.querySelector(`#size${imgId}`).innerText = `Size: ${Math.round(file.size / 1024).toPrecision(3)} kb`
                }
            } else {
                incorrectFiles += `${file.name} | ${file.size / 1024} kb | ${file.type} not meet requirements\n`
                // document.querySelector(`#_${file.name.split("@")[1].split(".")[0]}`).parentElement.style.border = "3px solid red"
            }

        }

        if (incorrectFiles) {
            incorrectFiles += `\nRequirements:\nname as downloaded\nsize between ${minImageSize / 1024}-${maxImageSize / 1024} kb\nextension ${possibleImageExtension}
            `
            alert(incorrectFiles)
        }
    }

    function reset_form() {

        document.querySelector("#photoEditForm").reset()

        for (let form of forms) {
            for (let applicant of form.applicants) {
                // document.querySelector("#_" + applicant.id).src = applicant.photoLink || applicant.originalPhotoLink
                document.querySelector("#_" + applicant.id).src = applicant.image
            }
        }
    }

    async function submit_form(e) {
        e.preventDefault()

        let response = await HttpRequest("/files/photo", {
            method: "PUT",
            body: new FormData(e.target)
        }, () => setIsSigned(null)).catch(e => false)


        if (response?.statusText === "OK") {
            props.setNotification(`Total saved ${response.data.totalSaved}`)

            const process = props.process
            props.setProcess(null)
            setTimeout(() => {
                props.setProcess(process)
            }, 0)
        } else {
            props.setNotification(response?.data?.msg || response?.statusText)
        }

    }

    async function closeForm() {
        props.setIsLoading(true)
        await HttpRequest(`/form/close`, {
            method: "PATCH",
            body: JSON.stringify({ formGuid: forms.map(form => form.guId) }),
            headers: {
                "Content-Type": "application/json"
            }
        }, () => setIsSigned(null))
        props.setIsLoading(false)

        props.setProcess(null)
    }

    async function markAsNotEditable(applicantId, formGuId) {

        let confirmation = window.confirm("Mark photo as not editable?")

        if (confirmation) {
            props.setIsLoading(true)

            let response = await HttpRequest(`/files/photo/not-editable?applicantId=${applicantId}&formGuId=${formGuId}`, { method: "PATCH" }, () => { props.setIsSigned(null) }).catch(e => false)

            if (response.statusText === "OK") {
                document.querySelector(`#form_${formGuId}`).style.display = "none";
            } else {
                props.setNotification(response.data?.msg || response.statusText || "Unknown error")
            }
            props.setIsLoading(false)
        }

    }


    useEffect(() => {

        get_forms_with_photo()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <ModalScreen zindex={2}></ModalScreen>

            {forms && (

                <form id="photoEditForm" style={formStyle} onSubmit={(e) => submit_form(e)}>

                    <CloseIcon fontSize={"large"}
                        style={{ position: "absolute", right: "0px", padding: "10px", cursor: "pointer" }}
                        onClick={closeForm} />

                    <div style={{ display: "flex", justifyContent: "center", gap: "100px", padding: "10px" }}>
                        <input type="button" style={downloadAllButton} value="Download all" onClick={download_all_photo} />
                        <label htmlFor="photos" style={{ ...downloadAllButton, backgroundColor: "green" }}>Upload all</label>
                        <input id="photos" type="file" name="photos" multiple={true} style={{ display: "none" }}
                            onClick={() => reset_form()}
                            onChange={(e) => upload_all_photo(e)}
                        />
                        <input type="submit" value="Submit" style={{ ...downloadAllButton, backgroundColor: "red" }} />
                    </div>

                    {forms.map((form, index) => (
                        <div id={"form_" + form.guId} key={form.guId} style={{ padding: "10px" }}>

                            <b>{form.guId}</b>
                            <hr />
                            <div style={{ display: "flex", gap: "15px", }}>
                                {form.applicants.map((applicant, index) => (
                                    <div key={"applicant_" + applicant.id} id={"applicant_" + applicant.id}>

                                        <div style={photoBlockStyle}>

                                            {applicant.photoLink && (
                                                <img style={floatPhotoStyle} src={applicant.imageOriginal} alt="applicant" />
                                            )}

                                            <img id={"_" + applicant.id} style={photoStyle}
                                                src={applicant.image} alt="applicant"
                                            />

                                        </div>


                                        <button
                                            type="button"
                                            style={downloadOriginalPhotoButton}
                                            onClick={() => download_image(
                                                applicant.originalPhotoLink,
                                                (applicant.photoLink || applicant.originalPhotoLink).split("/").reverse()[0],
                                                form.guId)
                                            }
                                        >
                                            Download original
                                        </button>
                                        <button
                                            type="button"
                                            style={downloadOriginalPhotoButton} onClick={() => { markAsNotEditable(applicant.id, form.guId) }}>
                                            Mark as not editable
                                        </button>
                                        <p style={{ margin: "2px" }}>First name: {applicant.firstName}</p>
                                        <p style={{ margin: "2px" }}>Gender: {applicant.gender}</p>
                                        <p style={{ margin: "2px" }}>Birth year: {applicant.birthYear}</p>
                                        <p style={{ margin: "2px" }} id={`wh_${applicant.id}`}>W x H: </p>
                                        <p style={{ margin: "2px" }} id={`size_${applicant.id}`}>Size: </p>

                                    </div>
                                ))}
                            </div>

                        </div>
                    ))}
                </form>
            )}

        </div>
    )
}

export default PhotoEditForm