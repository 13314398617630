import { Container } from "@mui/system";
import ModalScreen from "../components/ModalScreen";
import CircularProgress from '@mui/joy/CircularProgress';
import { useState } from "react";

import SearchBar from "../components/SearchBar";
import SearchResultBlock from "../components/SearchResultBlock";
import Navbar from "../components/Navbar";
import Notification from "../components/Notification";

import Form from "../components/Form";
import PhotoEditForm from "../components/PhotoEditForm";
import LoginForm from "../components/LoginForm";

import { useEffect, createContext, useContext } from "react";
import HttpRequest from "../Miscellaneous/HttpRequest";
import Settings from "../components/Settings";
import Agents from "../components/AgentsBlock";


const SessionContext = createContext();
export const useAuth = () => useContext(SessionContext);


function Dashboard() {

    let [isSigned, setIsSigned] = useState(null)

    let [process, setProcess] = useState(null)

    let [isLoading, setIsLoading] = useState(null)

    let [notification, setNotification] = useState(null)

    let [searchResult, setSearchResult] = useState(null)

    let [agents, setAgents] = useState(null)


    useEffect(() => {

        if (window.localStorage.getItem("accessToken")) {
            setIsSigned(true)
        }
        if (!isSigned) setIsLoading(null)

        async function checkSession() {
            await HttpRequest("/ping", {}, () => setIsSigned(null))
        }
        if (isSigned) checkSession()
        else setProcess(null)

    }, [isSigned])

    return (
        <Container maxWidth={"lg"} style={{ position: "relative" }}>

            {notification &&
                <Notification notification={notification} setNotification={setNotification}></Notification>
            }
            {isLoading && <ModalScreen><CircularProgress size={"lg"} /></ModalScreen>}


            {!isSigned && <LoginForm setIsLoading={setIsLoading} setNotification={setNotification} setIsSigned={setIsSigned}></LoginForm>}


            {isSigned &&
                <SessionContext.Provider value={{ isSigned, setIsSigned }}>
                    <Navbar setProcess={setProcess} />

                    <SearchBar setSearchResult={setSearchResult} setIsLoading={setIsLoading} setNotification={setNotification} />

                    {searchResult &&
                        <SearchResultBlock searchResult={searchResult} setSearchResult={setSearchResult} setNotification={setNotification}
                            setProcess={setProcess} setIsSigned={setIsSigned} setIsLoading={setIsLoading} />
                    }


                    {process?.name === "photo_edit" &&
                        <PhotoEditForm process={process} setProcess={setProcess} setNotification={setNotification} setIsLoading={setIsLoading} />
                    }


                    {process?.name === "settings" &&
                        <Settings setProcess={setProcess} setIsSigned={setIsSigned} setNotification={setNotification} setIsLoading={setIsLoading} />}


                    {process?.name === "agents" &&
                        <Agents setProcess={setProcess} setIsSigned={setIsSigned} setNotification={setNotification} setIsLoading={setIsLoading} />}


                    {![null, undefined, "photo_edit", "settings", "agents"].includes(process?.name) &&
                        <Form process={process} setProcess={setProcess} setNotification={setNotification} setIsLoading={setIsLoading} />
                    }
                </SessionContext.Provider>
            }



        </Container>
    )
}

export default Dashboard;