import HttpRequest from "./HttpRequest"

function FetchImageSecure(url) {
    return new Promise(async (resolve, reject) => {

        if (!url) return reject()

        let result = await HttpRequest(url.substring(url.indexOf("/files")), {}).catch(e => false)

        resolve(result.data)
    })
}

export { FetchImageSecure }