const Study = [
    "HIGH_SCHOOL_NO_DEGREE",
    "HIGH_SCHOOL_DIPLOMA",
    "COLLEGE_DEGREE",
    "VOCATIONAL_SCHOOL",
    "UNIVERSITY_DEGREE",
    "MASTER_DEGREE",
    "DOCTORATE_DEGREE"
]

export default Study