const FormSteps = Object.freeze({
    "WAITING_CREATION": "WAITING_CREATION",
    "SORT": "SORT",
    "RE_SORT": "RE_SORT",
    "CALL": "CALL",
    "RE_CALL": "RE_CALL",
    "REFUSED": "REFUSED",
    "PHOTO_EDIT": "PHOTO_EDIT",
    "FINAL_CHECK": "FINAL_CHECK",
    "WAITING_REGISTRATION": "WAITING_REGISTRATION"
})

export {
    FormSteps
}