import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModalScreen from "./ModalScreen"
import { useState, useEffect, useRef } from "react";
import CloseIcon from '@mui/icons-material/Close';
import HttpRequest from '../Miscellaneous/HttpRequest';
import DeleteIcon from '@mui/icons-material/Delete';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';


function Settings(props) {

    let [activeTab, setActiveTab] = useState(1);

    let [workers, setWorkers] = useState(null)

    let [stats, setStats] = useState(null)

    let workerFormRef = useRef()

    function changeActiveTab(event, tabName) {
        setActiveTab(tabName)
    }

    function closeForm() {
        props.setProcess(null)
    }

    async function deleteWorker(workerId) {

        let confirmation = window.confirm("Are You SURE?????")

        if (confirmation) {
            props.setIsLoading(true)

            let response = await HttpRequest(`/workers/${workerId}`, { method: "DELETE" }, () => props.setIsSigned(null)).catch(e => false)

            if (response.statusCode.toString().startsWith("2")) {
                setWorkers(response.data)
            } else {
                props.setNotification(response.data?.msg || response.statusText || "Unknown error")
            }

            props.setIsLoading(false)
        }

    }

    async function createWorker(e) {
        e.preventDefault()

        let confirmation = window.confirm("Create new Worker???")

        if (confirmation) {
            props.setIsLoading(true)


            let form = new FormData(e.target)
            let formData = Object.fromEntries(form.entries())

            e.target.reset()
            let response = await HttpRequest("/workers",
                { method: "POST", headers: { "content-type": "application/json" }, body: JSON.stringify(formData) },
                () => props.setIsSigned(null))
                .catch(e => false)

            if (response.statusCode.toString().startsWith("2")) {

                setWorkers(response.data)
            } else {
                props.setNotification(response.data?.msg || response.statusText || "Unknown error")
            }

            props.setIsLoading(null)
        }
    }

    async function transfer_forms(e) {
        e.preventDefault()

        console.log("123")

        const formData = new FormData(e.target)

        const response = await HttpRequest("/form/transfer", { method: "PATCH", body: formData }, () => { props.setIsSigned(null) }).catch(e => false)

        if (response.statusCode === 200) {
            props.setNotification("Trasfer was successful")
        } else {
            props.setNotification("Trasfer has not been made")
        }
    }

    async function clear_operator_forms(workerUsername) {

        const confirm = window.confirm("Are you sure?")

        if (confirm) {
            const response = await HttpRequest(`/form/reset-operator-forms?username=${workerUsername}`, { method: "PATCH" }, () => { props.setIsSigned(null) }).catch(e => false)

            if (response.statusCode === 200) {
                props.setNotification("Cleared successful")
            } else {
                props.setNotification("Nothing to clear")
            }
        }

    }


    const parentBlockStyle = {
        position: "absolute",
        top: "50px",
        left: 0,
        width: "100%",
        backgroundColor: "rgb(240 240 240)",
        boxSizing: "border-box",
        zIndex: 2,
        minHeight: "800px",
        overflowY: "auto"
    }


    useEffect(() => {

        async function getData() {
            if (activeTab === 1) {

                props.setIsLoading(true)
                let response = await HttpRequest("/workers", {}, () => props.setIsSigned(null)).catch(e => false)

                if (response.statusCode.toString().startsWith("2")) {
                    setWorkers(response.data)
                } else {
                    props.setNotification(response.statusText || "Unknown error")
                }

                props.setIsLoading(false)
            } else if (activeTab === 2) {

                props.setIsLoading(true)
                let response = await HttpRequest("/form/stats", {}, () => props.setIsSigned(null)).catch(e => false)

                if (response.statusText === "OK") {
                    setStats(response.data)
                } else {
                    props.setNotification(response.data?.msg || response.statusText || "Unknow error")
                }
                props.setIsLoading(false)

            }
        }

        getData()


    }, [activeTab])

    return (
        <>
            <ModalScreen zindex={1}></ModalScreen>

            <div style={parentBlockStyle}>
                <CloseIcon fontSize={"large"}
                    style={{ position: "absolute", right: "0px", padding: "10px", cursor: "pointer" }}
                    onClick={closeForm} />

                <Box borderBottom={1} borderColor={"devider"} position={"relative"} width={"90%"}>
                    <Tabs value={activeTab} onChange={changeActiveTab}>
                        <Tab value={1} label="Workers" />
                        <Tab value={2} label="Stats" />
                        <Tab value={3} label="Transfer" />
                    </Tabs>
                </Box>

                {activeTab === 1 && workers && (
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Username</TableCell>
                                        <TableCell align="right">Type</TableCell>
                                        <TableCell align="right">Created at</TableCell>
                                        {/* <TableCell align="right">Last login</TableCell> */}
                                        <TableCell align="right">Sorted</TableCell>
                                        <TableCell align="right">Called</TableCell>
                                        <TableCell align="right">Photo edited</TableCell>
                                        <TableCell align="right">Final check</TableCell>
                                        <TableCell align="right">Invoices issued</TableCell>
                                        <TableCell align="right">Paid invoices</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {workers.map(worker => (
                                        <TableRow
                                            key={worker.username}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {worker.username}
                                            </TableCell>
                                            <TableCell align="right">{worker.type}</TableCell>
                                            <TableCell align="right">{new Date(worker.createdAt + "Z").toLocaleString()}</TableCell>
                                            {/* <TableCell align="right">{new Date(worker.lastLogin + "Z").toLocaleString()}</TableCell> */}
                                            <TableCell align="right">{worker.stats.sorted}</TableCell>
                                            <TableCell align="right">{worker.stats.called}</TableCell>
                                            <TableCell align="right">{worker.stats.photoEdited}</TableCell>
                                            <TableCell align="right">{worker.stats.finalChecked}</TableCell>
                                            <TableCell align="right">{worker.stats.invoicesIssued}</TableCell>
                                            <TableCell align="right">{worker.stats.paidInvoices}</TableCell>
                                            <TableCell align="right">
                                                {worker.type === "OPERATOR" && (
                                                    <CleaningServicesIcon fontSize='medium'
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => clear_operator_forms(worker.username)}
                                                    />
                                                )}
                                                <DeleteIcon style={{ cursor: "pointer" }}
                                                    onClick={() => deleteWorker(worker.username)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <form name="newWorker" ref={workerFormRef} style={{ marginTop: "50px" }} onSubmit={(e) => createWorker(e)}>

                            <InputLabel style={{ color: "#1976d2" }}>Create new worker (use letters and numbers)</InputLabel>

                            <Grid2 container display={"flex"} alignItems={"end"} gap={"30px"}>
                                <Grid2 md={2}>
                                    <TextField label="Username" variant="standard" name={`username`}
                                        defaultValue={""} placeholder='4-20 characters' fullWidth />
                                </Grid2>
                                <Grid2 md={2}>
                                    <TextField label="Password" variant="standard" name={`password`}
                                        defaultValue={""} type='password' placeholder='6-10 characters' fullWidth />
                                </Grid2>
                                <Grid2 md={2}>
                                    <FormControl variant="standard" fullWidth>
                                        <Select
                                            defaultValue={""}
                                            label="Type"
                                            name={`type`}
                                        >
                                            <MenuItem value={""}>Choose</MenuItem>
                                            <MenuItem value={"SORTER"}>Sorter</MenuItem>
                                            <MenuItem value={"OPERATOR"}>Operator</MenuItem>
                                            <MenuItem value={"PHOTO_EDITOR"}>Photo editor</MenuItem>
                                            <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid2>
                                <Grid2 md={2}>
                                    <Button variant="contained" type='submit'>
                                        Create
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </form>
                    </>
                )}

                {activeTab === 2 && stats && (
                    <>
                        <Typography marginTop={"50px"}>Forms</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total</TableCell>
                                        <TableCell align="left">Good</TableCell>
                                        <TableCell align="left">Bad</TableCell>
                                        <TableCell align="left">Refused</TableCell>
                                        <TableCell align="left">Duplicates</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {stats.forms.totalForms}
                                        </TableCell>
                                        <TableCell align="left">{stats.forms.totalGoodForms}</TableCell>
                                        <TableCell align="left">{stats.forms.totalBadForms}</TableCell>
                                        <TableCell align="left">{stats.forms.totalRefusedForms}</TableCell>
                                        <TableCell align="left">{stats.forms.totalDuplicates}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography marginTop={"50px"}>Forms on process</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">On sort</TableCell>
                                        <TableCell align="left">On call</TableCell>
                                        <TableCell align="left">On photo edit</TableCell>
                                        <TableCell align="left">On final check</TableCell>
                                        <TableCell align="left">On registration</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{stats.formsOnProcess.totalFormsOnSort}</TableCell>
                                        <TableCell align="left">{stats.formsOnProcess.totalFormsOnCall}</TableCell>
                                        <TableCell align="left">{stats.formsOnProcess.totalFormsOnPhotoEdit}</TableCell>
                                        <TableCell align="left">{stats.formsOnProcess.totalFormsOnFinalCheck}</TableCell>
                                        <TableCell align="left">{stats.formsOnProcess.totalFormsOnRegistration}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Typography marginTop={"100px"}>Applicants 18+</Typography>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Total</TableCell>
                                        <TableCell align="left">Will participate</TableCell>
                                        <TableCell align="left">Registered</TableCell>
                                        <TableCell align="left">Bad marital status</TableCell>
                                        <TableCell align="left">Bad photo</TableCell>
                                        <TableCell align="left">Low study</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {stats.applicants.totalApplicants}
                                        </TableCell>
                                        <TableCell align="left">{stats.applicants.totalWillParticipate}</TableCell>
                                        <TableCell align="left">{stats.applicants.totalRegistered}</TableCell>
                                        <TableCell align="left">{stats.applicants.totalBadMaritalStatus}</TableCell>
                                        <TableCell align="left">{stats.applicants.totalBadPhoto}</TableCell>
                                        <TableCell align="left">{stats.applicants.totalBadStudy}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

                {activeTab === 3 && (
                    <Grid2 container>
                        <Grid2 md={12} marginTop={"50px"}>
                            <InputLabel htmlFor={"status_to_status"}
                                style={{
                                    width: "fit-content",
                                    padding: "5px",
                                }}
                            >
                                Send forms from step to step by status
                            </InputLabel>
                            <form
                                name="status_to_status"
                                onSubmit={(e) => transfer_forms(e)}
                                style={{ display: "flex", gap: "50px" }}
                            >
                                <FormControl variant="standard" style={{ width: "250px" }}>
                                    <InputLabel>From step</InputLabel>
                                    <Select
                                        defaultValue={""}
                                        label="From step"
                                        name={`fromStep`}
                                    >
                                        <MenuItem value={""}>Choose</MenuItem>
                                        <MenuItem value={"SORT"}>SORT</MenuItem>
                                        <MenuItem value={"CALL"}>CALL</MenuItem>
                                        <MenuItem value={"PHOTO_EDIT"}>PHOTO_EDIT</MenuItem>
                                        <MenuItem value={"FINAL_CHECK"}>FINAL_CHECK</MenuItem>
                                        <MenuItem value={"REGISTERED"}>REGISTERED</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" style={{ width: "250px" }}>
                                    <InputLabel>To step</InputLabel>
                                    <Select
                                        defaultValue={""}
                                        label="To step"
                                        name={`toStep`}
                                    >
                                        <MenuItem value={""}>Choose</MenuItem>
                                        <MenuItem value={"SORT"}>SORT</MenuItem>
                                        <MenuItem value={"CALL"}>CALL</MenuItem>
                                        <MenuItem value={"PHOTO_EDIT"}>PHOTO_EDIT</MenuItem>
                                        <MenuItem value={"FINAL_CHECK"}>FINAL_CHECK</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl variant="standard" style={{ width: "250px" }}>
                                    <InputLabel>Where status</InputLabel>
                                    <Select
                                        defaultValue={""}
                                        label="To step"
                                        name={`byStatus`}
                                    >
                                        <MenuItem value={""}>Choose</MenuItem>
                                        <MenuItem value={"GOOD"}>GOOD</MenuItem>
                                        <MenuItem value={"BAD"}>BAD</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button type='submit' variant='contained'>Transfer</Button>
                            </form>
                        </Grid2>
                    </Grid2>
                )}

            </div>
        </>
    )
}

export default Settings