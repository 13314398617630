

function ModalScreen(props) {

    let style = {
        backgroundColor: "rgba(0,0,0, 0.5)",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: props.zindex ? props.zindex : 3
    }


    return (
        <div style={style}>{props.children}</div>
    )
}

export default ModalScreen