
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

function Navbar(props) {

    return (
        <Grid2 container paddingY={"10px"}>
            <Grid2 xs={12} display="flex" justifyContent="center" gap="50px">
                <img src="/images/add_applicant.png" width="34" height="34" alt="new applicant"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "new" })} />

                <img src="/images/sort.png" width="34" height="34" alt="sort"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "sort" })} />

                <img src="/images/call.png" width="34" height="34" alt="call"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "call" })} />

                <img src="/images/camera.png" width="34" height="34" alt="photo_edit"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "photo_edit" })} />

                <img src="/images/clipboard.png" width="34" height="34" alt="final_check"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "final_check" })} />

                <img src="/images/network.png" width="34" height="34" alt="agents"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "agents" })} />

                <img src="/images/settings.png" width="34" height="34" alt="settings"
                    style={{ cursor: "pointer" }} onClick={() => props.setProcess({ "name": "settings" })} />

            </Grid2>
        </Grid2>
    )
}

export default Navbar