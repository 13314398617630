import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';



function Notification(props) {

    function close() {
        props.setNotification(null)
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={true}
            autoHideDuration={6000}
            onClose={close}
            message={props.notification}
        />
    )
}

export default Notification