import { Button, TextField } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import HttpRequest from "../Miscellaneous/HttpRequest"

function Login(props) {

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        minWidth: "100%",
        backgroundColor: "white",
        padding: "20px",
        boxShadow: "0px 0px 7px 2px rgba(0,0,0,0.1)"
    }


    async function submitForm(e) {
        e.preventDefault()

        let jsonBody = Object.fromEntries(new FormData(e.target))
        for (let key in jsonBody) {
            jsonBody[key] = jsonBody[key].trim()
        }


        const options = {
            method: "POST",
            body: JSON.stringify(jsonBody),
            headers: {
                "content-type": "application/json"
            }
        }
        props.setIsLoading(true)
        let response = await HttpRequest("/auth/signin", options).catch(e => false)
        props.setIsLoading(false)
        console.log(response)
        if (response.statusCode !== 200) {
            props.setNotification(response.data?.msg || "Unknown error")
        } else {
            if (response.data.accessToken && response.data.refreshToken) {

                window.localStorage.setItem("accessToken", `${response.data.accessToken}`)
                window.localStorage.setItem("refreshToken", `${response.data.refreshToken}`)

                props.setIsSigned(true)
            }
        }

    }

    return (
        <Grid2 container justifyContent={"center"} marginTop={"100px"}>
            <Grid2 md={4}>
                <form name="login" style={formStyle} onSubmit={(e) => submitForm(e)}>
                    <TextField variant="standard" label={"Username"}
                        name={"username"} defaultValue={""} fullWidth />
                    <TextField variant="standard" label={"Password"}
                        name={"password"} defaultValue={""} fullWidth type="password" />

                    <Button variant="contained" type="submit" style={{ marginTop: "20px" }}>Sign in</Button>
                </form>
            </Grid2>
        </Grid2>
    )
}

export default Login