const Countries = [
    "MOLDOVA",
    "UZBEKISTAN",
    "KYRGYZSTAN",
    "KAZAKHSTAN",
    "AZERBAIJAN",
    "ROMANIA",
    "UKRAINE",
    "RUSSIA",
    "BELARUS",
    "GEORGIA",
    "TAJIKISTAN",
    "AFGHANISTAN",
    "ALBANIA",
    "ALGERIA",
    "ANGOLA",
    "ARGENTINA",
    "ARMENIA",
    "AUSTRALIA",
    "AUSTRIA",
    "BAHAMAS",
    "BAHRAIN",
    "BARBADOS",
    "BELGIUM",
    "BOLIVIA",
    "BOSNIA_HERZEGOVINA",
    "BRITISH_VIRGIN_ISLANDS",
    "BULGARIA",
    "CHILE",
    "COSTA_RICA",
    "CROATIA",
    "CUBA",
    "CYPRUS",
    "CZECH_REPUBLIC",
    "DENMARK",
    "DOMINICA",
    "ECUADOR",
    "EGYPT",
    "ESTONIA",
    "FINLAND",
    "FRANCE",
    "GERMANY",
    "GREECE",
    "HUNGARY",
    "ICELAND",
    "INDONESIA",
    "IRAN",
    "IRAQ",
    "IRELAND",
    "ISLE_OF_MAN",
    "ISRAEL",
    "ITALY",
    "JAPAN",
    "JERSEY",
    "JORDAN",
    "LATVIA",
    "LEBANON",
    "LIBERIA",
    "LIBYA",
    "LIECHTENSTEIN",
    "LITHUANIA",
    "LUXEMBOURG",
    "MACEDONIA",
    "MALAYSIA",
    "MALDIVES",
    "MALI",
    "MALTA",
    "MONACO",
    "MONGOLIA",
    "MONTENEGRO",
    "MONTSERRAT",
    "MOROCCO",
    "NETHERLANDS",
    "NEW_ZEALAND",
    "NORTH_KOREA",
    "NORWAY",
    "OMAN",
    "PANAMA",
    "PARAGUAY",
    "PERU",
    "POLAND",
    "PORTUGAL",
    "PUERTO_RICO",
    "QATAR",
    "SAN_MARINO",
    "SAUDI_ARABIA",
    "SERBIA",
    "SEYCHELLES",
    "SINGAPORE",
    "SLOVAKIA",
    "SLOVENIA",
    "SPAIN",
    "SRI_LANKA",
    "SWAZILAND",
    "SWEDEN",
    "SWITZERLAND",
    "SYRIA",
    "THAILAND",
    "TUNISIA",
    "TURKEY",
    "TURKMENISTAN",
    "UNITED_ARAB_EMIRATES",
    "UNITED_KINGDOM",
    "UNITED_STATES_OF_AMERICA",
    "URUGUAY",
    "VATICAN_CITY",
    "VENEZUELA"
]

export default Countries